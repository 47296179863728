import React from 'react';
import { Box, Button, Text, Center, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown'

const PrivacyPopup = ({ isOpen, onClose, bodyText, method }) => {

    function LinkRenderer(props) {
        return (
          <a href={props.href} target="_blank" rel="noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
            {props.children}
          </a>
        );
      }

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size={method === "widget" ? "sm" : "lg"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Privacy Policy</ModalHeader>
                <ModalBody>
                    <ReactMarkdown
                    children={bodyText}
                    components={{ a: LinkRenderer }}
                  />
                </ModalBody>
                <ModalFooter>
                    <Button bg="black" color="white" _hover={{ bg: "gray.700" }} onClick={onClose}>Accept Terms</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default PrivacyPopup;
import React from 'react';
import {  BrowserRouter, Routes, Route } from 'react-router-dom';
import Chat from './Chat';
import { Helmet } from 'react-helmet';

function App() {
  return (
      <Routes>
          <Route path="/:id" element={<Chat />} />
      </Routes>
  );
}

export default App;

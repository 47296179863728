import React from "react";
import { Flex, Input, InputGroup, InputRightElement, Text, VStack, IconButton } from "@chakra-ui/react";
import { IoSend } from "react-icons/io5";

const Footer = ({ inputMessage, setInputMessage, handleSendMessage, loading, powered_by, demo, customTypeMessage, backgroundColor }) => {
  return (
    <VStack mb = "1" bgColor = {backgroundColor ? backgroundColor : undefined}>
    <Flex w="100%" mt="5" flexDirection = "row">
    <InputGroup mx = "5">
        <InputRightElement onClick={() => {
              if (demo) {
                  alert('Sending messages is disabled in preview mode.')
              }
            }}>
          <IconButton isDisabled={loading} onClick = {() => handleSendMessage()} variant = "ghost" colorScheme = "black">
            <IoSend color='gray.300' />
          </IconButton>
        </InputRightElement>
        <Input
            maxLength = {500}
            placeholder= {customTypeMessage ? customTypeMessage : "Type your message..."}
            bgColor = "white"
            _focus={{
            border: "1px solid gray",
            }}
            onKeyPress={(e) => {
            if (e.key === "Enter") {
                if (demo) {
                  alert('Sending messages is disabled in preview mode.')
                }
                if (loading) {
                  return;
                }
                handleSendMessage();
                
            }
            }}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}         
        />
    </InputGroup>
    </Flex>
    {powered_by ? <Text fontSize = "xs">Chat by <a href="https://www.botsheets.com" target="_blank" rel="noreferrer"><Text as='u'>Botsheets</Text></a></Text>: <Text fontSize = "xs"> </Text>}
    </VStack>
  );
};

export default Footer;

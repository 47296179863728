import React, { useEffect, useState, useRef } from "react";
import { Flex, Text, Box, Input, Textarea, Button, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Image,
  IconButton } from "@chakra-ui/react";
import './MessagesCSS.css';
import ReactMarkdown from 'react-markdown'
import Linkify from 'react-linkify';
import ScrollableTable from "./ScrollableTable";
import { RiShareBoxFill } from "react-icons/ri";

const MessagesAnalysis = ({ messages, loading, bot_id, onExplainResult, userMessageColor, botMessageColor, testBot }) => {

  const messagesContainerRef = useRef();

  useEffect(() => {
    // Scroll to the bottom of the container with smooth scrolling
    messagesContainerRef.current.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  function LinkRenderer(props) {
    console.log({ props });
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }

  const componentDecorator = (href, text, key) => (
    <a className="linkify__text" href={href} style={{ color: 'blue', textDecoration: 'underline' }} key={key} target="_blank">
      {text}
    </a>
  );

  function downloadImage(imageUrl) {
    window.open(imageUrl, '_blank')
  }


  const pickTextColorBasedOnBgColorSimple = (bgColor, lightColor, darkColor) => {
    console.log("PICKING COLOR", bgColor, lightColor, darkColor)
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    const colorValue = (r * 0.299) + (g * 0.587) + (b * 0.114);
    console.log("Color Value", colorValue)
    console.log("chosen color", colorValue > 190 ? darkColor : lightColor)
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 190) ?
      darkColor : lightColor;
  }
  

  return (
    <Flex w="100%" h="100%" overflowY="auto" flexDirection="column" p = "3" ref={messagesContainerRef}>
      {messages.map((item, index) => {
        if (item.from === "me") {
          return (
            <Flex key={index} w="100%" justify="flex-end">
              <Flex
                bg = {userMessageColor ? userMessageColor : "gray.500"}
                color = {userMessageColor ? pickTextColorBasedOnBgColorSimple(userMessageColor ? userMessageColor : 'gray.500', '#FFFFFF', '#000000') : '#FFFFFF'}
                maxW="700px"
                my="1"
                p="3"
                borderRadius="md"
              >
                <Text>{item.text}</Text>
              </Flex>
            </Flex>
          );
        } else if (item.from === "dataframe") {
          return (
            <Flex pt = "3" w = "100%" flexDir = "column">
              <Box
                borderColor = "gray.300"
                color = {botMessageColor ? pickTextColorBasedOnBgColorSimple(botMessageColor ? botMessageColor : 'gray.300', '#FFFFFF', '#000000') : '#000000'}
                borderWidth="2px"
                borderRadius="lg"
                p={6}
                maxW = "90%"
              >
                <ScrollableTable data = {item.text} />
              </Box>
          </Flex>
          )
        } else if (item.from === "image") {
            return (
            <Flex w="100%" pt="3" flexDir = "column">
                <Flex
                  w="90%"
                  maxW="90%"
                  maxH="400px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Image
                    src={item.text}
                    alt="Displayed image"
                    w="500px"
                  />
                </Flex>

                <Flex
                  w="500px"
                  maxW="90%"
                  bg="gray.300"
                  justifyContent="flex-end"
                  alignItems="center"
                  p="1"
                  borderRadius="lg"
                  borderTopRadius="0"
                >
                  <IconButton
                    size="sm"
                    bgColor="transparent"
                    aria-label="Save image"
                    icon={<RiShareBoxFill />}
                    onClick={() => downloadImage(item.text)}
                  />
                </Flex>
              </Flex>
            );
        } else {
          return (
            <Flex key={index} w="100%"> 
               <Flex
                borderRadius="md"
                bg= {botMessageColor ? botMessageColor : "gray.300"}
                maxW="700px"
                my="1"
                p="3"
                color = {botMessageColor ? pickTextColorBasedOnBgColorSimple(botMessageColor ? botMessageColor : 'gray.300', '#FFFFFF', '#000000') : '#000000'}
                sx={{
                  a: {
                    color: 'blue', // Sets the text color to blue
                    textDecoration: 'underline', // Adds an underline to the text
                  },
                  ul: {
                    listStylePosition: 'inside', // Ensures bullet points are inside the container
                    margin: 0, // Removes default margin
                    padding: 0, // Adjust padding as needed, or remove it
                  },
                  ol: {
                    listStylePosition: 'inside', // Ensures numbers are inside the container
                    margin: 0, // Removes default margin
                    padding: 0, // Adjust padding as needed, or remove it
                  },
                  // You might also want to style 'li' elements for further adjustments
                  li: {
                    // Add styles for list items if needed
                  },
                }}
                flexDir = "column"
              >
                 <ReactMarkdown
                    children={item.text}
                    components={{ a: LinkRenderer }}
                  />
              </Flex>
            </Flex>
          );
        }
      })}

        {loading && (
        <div className="half light ">
          <div className="typing">
            <span className="circle bouncing"></span>
            <span className="circle bouncing"></span>
            <span className="circle bouncing"></span>
          </div>
          <br />
        </div>
        )}
    </Flex>
  );
};

export default MessagesAnalysis;

import React from "react";
import { Flex, Text, Badge } from "@chakra-ui/react";

const Header = (props) => {

  const pickTextColorBasedOnBgColorSimple = (bgColor, lightColor, darkColor) => {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 190) ?
      darkColor : lightColor;
  }

  console.log(pickTextColorBasedOnBgColorSimple(props.widgetColor, '#FFFFFF', '#123123'))

  return (
    <Flex bgColor = {props.widgetColor} w="100%" h = "50px">
      <Flex flexDirection="column" mx="5" justify="center">
        <Text color = {pickTextColorBasedOnBgColorSimple(props.widgetColor, '#FFFFFF', '#000000')} my = "3"fontSize="lg" fontWeight="bold">
          {props.visibleName ? props.visibleName : "Chatbot"}
          <Badge ml = "5">
            {props.badge_visibility ? "Powered by AI" : ""}
          </Badge>
        </Text>
      </Flex>
    </Flex>
  );
};

export default Header;
